<template>
  <b-container>
    <b-alert variant="danger" :show="showError">
      <span class="circle-error">!</span>
      <span>{{ errorMessage }}</span>
    </b-alert>
    <div>
      <form id="form" v-on:submit.prevent="sendReceipt" v-show="loaded">
        <p>Your payment has been successfully processed - thank you!</p>
        <b-row class="mb-2 d-print-none">
          <b-col cols="12" md="10" lg="7">
            <b-alert variant="info" show>
              <span class="circle-info">i</span>
              <span>
                Please note, the payment may take up to 3 business days to be
                reflected on your account.
              </span>
            </b-alert>
          </b-col>
        </b-row>
        <div class="row mt-4 d-print-none" v-show="!isEmailSent">
          <p>
            If you would like to receive a copy of this receipt, please enter
            your email below and click “Send receipt in Email”.
          </p>
        </div>
        <div class="row mb-2 d-print-none" v-show="!isEmailSent">
          <div class="col-md-3">
            <label class="form-label" for="email">Email:</label>
            <input
              class="form-control"
              v-model.trim="$v.email.$model"
              type="email"
              id="email"
            />
            <div class="validator_wrap" v-if="$v.email.$dirty">
              <div class="error" v-if="!$v.email.email">
                Enter correct email
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <input
              id="send-receipt"
              :disabled="isDisabledEmail"
              class="btn btn-primary"
              value="Send receipt in Email"
              type="submit"
            />
          </div>
        </div>
        <div class="row mt-4 d-print-none" v-show="isEmailSent">
          <p class="email_confirmation">
            Your receipt has been sent to "{{ email }}".
          </p>
        </div>
        <div id="print">
          <b-row class="">
            <span class="col-4 col-md-3 col-lg-2">Receipt number:</span>
            <span class="col-5 col-md-3 total">HUME{{ paymentReference }}</span>
            <a class="col d-print-none print-receipt" href="#" @click="print">
              Print receipt
            </a>
          </b-row>
          <b-row class="mb-2">
            <span class="col-4 col-md-3 col-lg-2">Payment date:</span>
            <span class="col-5 col-md-3 total">{{ paymentDate }}</span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-4 col-md-3 col-lg-2">Customer number:</span>
            <span class="col-5 col-md-3 total">{{ tenantCode }}</span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-4 col-md-3 col-lg-2">Payer name:</span>
            <span class="col-5 col-md-3 total">{{ payerName }}</span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-4 col-md-3 col-lg-2">Payment amount:</span>
            <span class="col-5 col-md-3 total">${{ totalAmount }}</span>
          </b-row>

          <b-row class="mt-4 mb-2">
            <span class="col-3 col-md-2 col-lg-2 th border-top border-right">
              Account
            </span>
            <span class="col-2 col-md-2 col-lg-1 th border-top border-right">
              Amount Due
            </span>
            <span class="col-2 col-md-2 col-lg-1 th border-top">Paid</span>
            <span class="col-5 col-md-4 col-lg-3 border-top"></span>
          </b-row>
          <b-row class="mb-2" v-show="bondPaid > 0">
            <span class="col-3 col-md-2 col-lg-2 border-top border-right">
              Bond
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top border-right">
              ${{ bond }}
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top">
              ${{ bondPaid }}
            </span>
            <span class="col-5 col-md-4 col-lg-3 border-top"></span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-3 col-md-2 col-lg-2 border-top border-right">
              Rent
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top border-right">
              ${{ rent }}
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top">
              ${{ rentPaid }}
            </span>
            <span class="col-5 col-md-4 col-lg-3 border-top">
              Paid up to {{ paidUpToCalculated }}
            </span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-3 col-md-2 col-lg-2 border-top border-right">
              Water usage
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top border-right">
              ${{ water }}
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top">
              ${{ waterPaid }}
            </span>
            <span class="col-5 col-md-4 col-lg-3 border-top"></span>
          </b-row>
          <b-row class="mb-2">
            <span class="col-3 col-md-2 col-lg-2 border-top border-right">
              Maintenance
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top border-right">
              ${{ maintenance }}
            </span>
            <span class="col-2 col-md-2 col-lg-1 border-top">
              ${{ maintenancePaid }}
            </span>
            <span class="col-5 col-md-4 col-lg-3 border-top"></span>
          </b-row>
        </div>
        <div id="footnote" class="footnote d-print-block">
          <p>Note:</p>
          <ul>
            <li>
              The rent due includes the amount required to pay your rent 14 days
              in advance in accordance with the Residential Tenancies Act.
            </li>
            <li>
              Rent due is based on current weekly rent and does not include any
              future rent changes as a result of rent review.
            </li>
            <li>
              If you have a shared water meter you should include an additional
              2 weeks water charges to ensure your account remains paid up to
              date.
            </li>
            <li>
              Balances may not reflect payments made within last 3 business
              days.
            </li>
          </ul>
        </div>
        <div class="mt-4 mb-4">
          <a class="d-print-none" href="/">Return to Home page</a>
        </div>
      </form>
      <div class="text-center mt-5 mb-5" v-show="!loaded && !showError">
        <b-spinner label="Loading..."></b-spinner>
        <p id="cancel-label">
          We are looking up your payment information, please wait...
        </p>
      </div>
    </div>
  </b-container>
</template>
<script>
import axios from 'axios';
import { email } from 'vuelidate/lib/validators';

export default {
  name: 'receipt',
  data() {
    return {
      paymentReference: null,
      paymentDate: null,
      tenantCode: null,
      payerName: '',
      totalAmount: null,
      waterPaid: 0,
      bondPaid: 0,
      maintenancePaid: 0,
      rentPaid: 0,
      paidUpToCalculated: '',
      water: 0,
      bond: 0,
      maintenance: 0,
      rent: 0,
      errorMessage: null,
      showError: false,
      loaded: false,
      email: '',
      isEmailSent: false,
      isDisabledEmail: true,
    };
  },
  validations: {
    email: {
      email,
    },
  },
  watch: {
    email(val) {
      if (val && !this.$v.$anyError) {
        this.isDisabledEmail = false;
      }
    },
  },
  methods: {
    print() {
      // Pass the element id here
      // this.$htmlToPaper('print');
      window.print();
    },
    sendReceipt() {
      const emailData = {
        email: this.email,
      };
      axios
        .post(
          `/api/sendreceipt?code=${process.env.VUE_APP_API_KEY_SEND_RECEIPT}`,
          emailData
        )
        .then(() => {
          this.isEmailSent = true;
        })
        .catch((error) => {
          this.showError = true;
          if (error.response.status === 400) {
            this.errorMessage =
              'Your query can not processed. Invalid or missing parameters.';
          } else if (error.response.status === 401) {
            this.errorMessage =
              'Unauthorized request. Your session is expired.';
          } else {
            this.errorMessage =
              'Your query can not be processed. Server error.';
          }
        });
    },
  },
  mounted() {
    if (!localStorage.userLoggedIn) {
      return;
    }

    const months = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    };

    axios
      .get(`/api/receipt?code=${process.env.VUE_APP_API_KEY_RECEIPT}`)
      .then((response) => {
        // cancel session redirect timeout and any other timeouts
        let timeoutId = window.setTimeout(() => {}, 0);
        while (timeoutId) {
          window.clearTimeout(timeoutId); // will do nothing if no timeout with id is present
          timeoutId -= 1;
        }
        /// /

        this.response = response.data;
        const receiptObj = JSON.parse(JSON.stringify(this.response));
        this.paymentReference = receiptObj.receiptNumber;
        this.tenantCode = receiptObj.tenantCode;
        this.totalAmount = receiptObj.amount;
        this.payerName = receiptObj.payer;
        const paymentDate = new Date(receiptObj.paymentDate);
        this.paymentDate = `${paymentDate.getDate()} ${
          months[paymentDate.getMonth()]
        } ${paymentDate.getFullYear()}`;
        this.water = receiptObj.water > 0 ? receiptObj.water : 0;
        this.waterPaid = receiptObj.waterPaid;
        this.bond = receiptObj.bond > 0 ? receiptObj.bond : 0;
        this.bondPaid = receiptObj.bondPaid;
        this.rent = receiptObj.rent;
        this.rentPaid = receiptObj.rentPaid;
        this.paidUpToCalculated = receiptObj.paidUpToCalculated;
        this.maintenance =
          receiptObj.maintenance > 0 ? receiptObj.maintenance : 0;
        this.maintenancePaid = receiptObj.maintenancePaid;
        this.loaded = true;
      })
      .catch((error) => {
        this.showError = true;
        if (error.response.status === 404) {
          this.errorMessage = 'Payment receipt is not found.';
        } else if (error.response.status === 400) {
          this.errorMessage =
            'Your query can not processed. Invalid or missing parameters.';
        } else if (error.response.status === 401) {
          this.errorMessage = 'Unauthorized request. Your session is expired.';
          setTimeout(() => {
            this.showError = false;
            this.$router.push({
              name: 'search',
            });
          }, 1500);
        } else {
          this.errorMessage = 'Your query can not be processed. Server error.';
        }
      });
  },
};
</script>
